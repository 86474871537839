.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    position: absolute;
    top: calc(100% + 8px); 
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8); 
    color: white;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 0.75rem; 
    white-space: nowrap;
    z-index: 10;
    transition: opacity 0.3s ease, transform 0.3s ease; 
    opacity: 0;
    transform: translate(-50%, 10px); 
  }
  
  .tooltip-container:hover .tooltip {
    opacity: 1; 
    transform: translate(-50%, 0); 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
  }
  
.tree-container {
    font-family: "Arial", sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #2c3e50; /* Dark background */
    color: #fff; /* Light text */
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
  
  .tree-heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Node styling */
  .tree-node {
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #34495e; /* Dark border */
    transition: all 0.3s ease;
    background-color: #34495e; /* Dark node background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: relative; 
  }
  
  .tree-node:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
    background-color: #2c3e50; /* Dark Blue on hover */
  }
  
  .node-content {
    display: flex;
    align-items: center;
  }
  
  .expand-button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
    color: #ccc; /* Light button color */
    font-size: 1.2em;
  }
  
  .expand-button:hover {
    color: #fff; /* Light button color on hover */
  }
  
  .node-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff; /* Light text color */
  }
  
  .node-label input[type="radio"] {
    margin-right: 8px;
    opacity: 1; /* Make radio button visible */
    position: relative; 
    left: 0;
  }
  
  .node-label span {
    margin-right: 10px;
  }
  
  .children {
    margin-top: 10px;
    padding-left: 20px;
    list-style-type: none;
  }
  
  /* Color variations based on node position */
  .tree-node:nth-child(odd) {
    background-color: #2c3e50; /* Dark Blue */
    border-color: #34495e; /* Darker Blue */
  }
  
  .tree-node:nth-child(even) {
    background-color: #273c75; /* Dark Indigo */
    border-color: #353b48; /* Darker Indigo */
  }
  
  .tree-node:nth-child(3n + 1) {
    background-color: #4a69bd; /* Dark Blue */
    border-color: #192a56; /* Darker Blue */
  }
  
  .tree-node:nth-child(3n + 2) {
    background-color: #40739e; /* Dark Cyan */
    border-color: #192a56; /* Darker Cyan */
  }
  
  .tree-node:nth-child(3n + 3) {
    background-color: #353b48; /* Dark Gray */
    border-color: #2c3e50; /* Darker Gray */
  }
  
  .tree-node:nth-child(4n + 1) {
    background-color: #1e272e; /* Dark Black */
    border-color: #192a56; /* Darker Black */
  }
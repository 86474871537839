.dialog-title {
    display: flex;
    align-items: center;
    background-color: #3f51b5;
    color: white;
    padding: 16px;
  }
  .dialog-title i {
    margin-right: 10px;
  }
  .search-field {
    margin-bottom: 16px;
  }
  .list-container {
    /* max-height: 400px; */
    display: grid !important;
    height: 45vh;
    /* height: 400px; */
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    background-color: #f9f9f9;
    margin-top: 25px;
    /* border: 1px solid #3F51B5; */
  }
  .booth-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .assigned-booth {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin-bottom: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  .assigned-booth:hover {
    background-color: #d0d0d0;
  }
  .actions {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    background-color: #f0f0f0;
  }
:root {
    /* --body-main-dark-color: #243C5C; */
    --body-main-light-color: #B3C9E6;
    /* --body-main-light-color: #9dbadfc7; */
    /* --body-main-light-color: #6182aac7; */
    /* --body-main-light-color: #849dbd; */
    /* --sidebar-bg-light-color: #f1f1f1; */
    --sidebar-bg-light-color: #e2e5e7;
    --sidebar-ul-hover-color: #ddd;
    --white: #fff;
    --sidebar-font-color: #333;
    --sidebar-bg-light: rgb(37, 37, 66);
    --sidebar-bg-dark: #555;
    --main-bg-light: #fff;
    --main-bg-dark: #444;
    --button-bg-light: #007bff;
    --button-hover-bg-light: #0056b3;
    --button-bg-dark: #bbb;
    --button-hover-bg-dark: #999;
    --button-color-light: #fff;
    --button-color-dark: #333;
    --table-border-light: #ddd;
    --table-border-dark: red;
    --text-color-dark: #fff;
}

.Employee_header {

    display: flex;
    flex-direction: row;
    width: 100%;

}

.Profile_Pic_div {
    display: flex;
    flex-direction: column;
    width: 18%;
    justify-content: center;
    align-items: center;
}

.Profile_img img {
    border-radius: 50%;
    height: 100px;

}

.prfoile_upload {
    display: flex;
    justify-content: center;
}

.upload_btn {
    background-color: var(--body-main-dark-color);
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    height: 30%;
    flex-direction: row;
    display: flex;
    border: 1px solid var(--body-main-dark-color);
    color: var(--button-color-light);
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    width: 100px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);

}

.Profile_dtl {
    display: grid;
    width: inherit;
    grid-template-columns: repeat(4, 1fr);
}

.profile_grid_c {
    margin-right: 10px;
}

.Emp_slider {
    background-color: #ffffff2b;
    border-radius: 100px;
    padding: 1px;
    margin: 10px;
    margin-top: 20px;
    cursor: pointer;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    align-items: center;
    position: relative;
    display: block;
    width: 41px;
    height: 20px;
    box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset,
        var(--body-main-light-color) 0px 0px 0px 0px inset, rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.Emp_slider::after {
    content: "";
    display: flex;
    top: 2.3px;
    left: 2px;
    width: 18px;
    height: 18px;
    background-color: #e3e3e3;
    border-radius: 200px;
    position: absolute;
    box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
    transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    will-change: left, background-color;
}

.Emp_Switch input[type="checkbox"]:checked+.Emp_slider {
    box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, var(--body-main-dark-color) 0px 0px 0px 2px inset, var(--body-main-dark-color) 0px 0px 0px 24px inset,
        rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.Emp_Switch input[type="checkbox"]:checked+.Emp_slider::after {
    left: 24px;
}

.Emp_Switch input[type="checkbox"] {
    display: none;
}

.user_isActive {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* flex-direction: row; */
    /* align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 190%;
    left: 90%; */
    
}

.user_main {
    /* padding: 10px; */
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    
    /* background-color: yellow; */
}

.User-Main
{
    overflow-y: hidden;
}
.user_content {
    width: 90%;
    /* border: 1px solid var(--body-main-dark-color); */
    border-radius: 10px;
    padding: 5px;
    display: flex !important;
    justify-content: center !important;

    /* background-color: blue; */

}

.grid-container-user {
    display: grid;
    width: inherit;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    /* row-gap: 20px; */
    row-gap: 10px;

    /* overflow-x: hidden; */
}
.flex-container-user{
    /* display: flex;
    flex-direction: row !important;
    justify-content: space-around; */
    display: grid;
    width: inherit;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 5px;
    justify-content: center;
}

.btnSave {
    /* background-color: var(--body-main-dark-color); */
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    height: 4%;
    flex-direction: row;
    display: flex;
    border: 1px solid var(--body-main-dark-color);
    color: var(--body-main-dark-color);
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
    align-content: center;

}

.UserDropDown .MuiFormHelperText-root{
    margin-left: 0 !important;
}


.grid-container-user .MuiInputBase-root{
    width: 400px !important;
}

.position-field .MuiInputBase-root{
    width: 1200px !important;
}
.MuiStack-root .MuiButtonBase-root{
    margin: 0 !important;
}

@media (max-width:1370px) {
    .grid-container-user .MuiFormControl-root{
        width: 250px !important;
    }

    .grid-container-user .MuiInputBase-root{
        width: 250px !important;
    }

    .position-field .MuiInputBase-root{
        width: 730px !important;
    }

    .position-field .MuiFormControl-root{
        width: 730px !important;
    }

    .user_main {
        justify-content: flex-end !important;
    }
    .User-Main{
        overflow-y: auto;
    }
}

/* @media (max-width:1200px) {
    .grid-container-user .MuiFormControl-root{
        width: 250px !important;
    }

    .grid-container-user .MuiInputBase-root{
        width: 250px !important;
    }

    .position-field .MuiInputBase-root{
        width: 600px !important;
    }

    .position-field .MuiFormControl-root{
        width: 600px !important;
    }
} */
@media (max-width:850px) {
    .Profile_dtl {
        grid-template-columns: repeat(4, 1fr);
    }

    .mani2 {
        overflow-x: auto;
    }

    .user_isActive {
        left: 80%;
    }
    .grid-container-user{
        grid-template-columns: repeat(2, 1fr);
    }
    .position-field .MuiInputBase-root{
        width: 500px !important;
    }

    .position-field .MuiFormControl-root{
        width: 500px !important;
    }
    .User-Main{
        overflow-y: auto;
    }
}

@media (max-width:650px) {
    .Profile_dtl {
        grid-template-columns: repeat(2, 1fr);
    }
    .grid-container-user{
        grid-template-columns: repeat(2, 1fr);
    }
    .position-field .MuiInputBase-root{
        width: 400px !important;
    }

    .position-field .MuiFormControl-root{
        width: 400px !important;
    }
    .User-Main{
        overflow-y: auto;
    }


}

@media (max-width:450px) {

    .User-Main{
    overflow-y: auto;
    }
    .Profile_dtl {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }

    .Employee_header {
        flex-direction: column;
        align-items: center;

    }
    .grid-container-user{
        grid-template-columns: repeat(1, 1fr);
    }
    .position-field .MuiInputBase-root{
        width: 250px !important;
    }

    .position-field .MuiFormControl-root{
        width: 250px !important;
    }
}
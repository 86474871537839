:root {

    /* --body-main-dark-color: #243C5C; */
    /* --new-blue: #3272c5; */
    --new-blue: #0d50a7;
    --new-blue-light: #7aaff5;
    --body-main-orange-color: #fad0a6;
    --body-main-green-color: #008000;
    --body-main-dark-orange: #f68109;
    --body-main-light-color: #B3C9E6;
    /* --body-main-light-color: #9dbadfc7; */
    /* --body-main-light-color: #6182aac7; */
    /* --body-main-light-color: #849dbd; */
    /* --sidebar-bg-light-color: #f1f1f1; */
    --sidebar-bg-light-color: #e2e5e7;
    --sidebar-ul-hover-color: #ddd;
    --white: #fff;
    --sidebar-font-color: #333;
    --sidebar-bg-light: rgb(37, 37, 66);
    --sidebar-bg-dark: #555;
    --main-bg-light: #fff;
    --main-bg-dark: #444;
    --button-bg-light: #007bff;
    --button-hover-bg-light: #0056b3;
    --button-bg-dark: #bbb;
    --button-hover-bg-dark: #999;
    --button-color-light: #fff;
    --button-color-dark: #333;
    --table-border-light: #ddd;
    --table-border-dark: red;
    --text-color-dark: #fff;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}


::-webkit-scrollbar-thumb {
    background: var(--body-main-dark-color);
    border-radius: 5px;
}


.dash {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    overflow: hidden;

}

.head {
    position: sticky;
    top: 0;
    height: 30px;
    /* background-color: var(--body-main-dark-color); */
    background-color: var(--new-blue);
    padding: 10px;
    z-index: 1;
    /* margin-left: 15%; */
    /* width: 84%; */
    margin-left: 230px;
    width: calc(100% - 230px);
    transition: all 0.3s ease-in-out;
}

.mui-head {
    background-color: var(--body-main-light-color);
}

.head-logo-content {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ff9933;
    background: linear-gradient(142deg,#ff9933 0%, #ffffff 50%, #138808 100%);
    background: -webkit-linear-gradient(142deg,#ff9933 0%, #ffffff 50%, #138808 100%);
    background: -moz-linear-gradient(142deg,#ff9933 0%, #ffffff 50%, #138808 100%);
}

.logo-name {
    color: var(--body-main-dark-color);
    font-size: medium;
    font-weight: 700;
}

.head-hr {
    background-color: var(--body-main-orange-color);
    color: var(--body-main-orange-color);
    margin-top: -0.5px;
    height: 1px;
}


.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    /* width: 15%; */
    width: 230px;
    height: 100%;
    /* background-color: var(--sidebar-bg-light-color); */
    background-color: var(--white);
    transition: all 0.3s ease-in-out;
    transform: translateX(0);
    box-shadow: 5px 0px 5px 0px rgba(128,128,128,0.75);
    -webkit-box-shadow: 5px 0px 5px 0px rgba(128,128,128,0.75);
    -moz-box-shadow: 5px 0px 5px 0px rgba(128,128,128,0.75);

}

.sidebar ul {
    list-style-type: none;
    padding: 0;

}

.sidebar li {
    margin-bottom: 10px;
    color: var(--body-main-orange-color);
}

.li-content {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    /* color: var(--body-main-dark-color); */
    color: var(--new-blue);
    font-weight: 400;
    font-size: small;
    transition: all 0.3s ease-in;
}

.li-content1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 8%;
    align-items: center;
    /* color: var(--body-main-dark-color); */
    color: var(--new-blue);
    font-weight: 400;
    font-size: small;
    transition: all 0.3s ease-in;
}


.submemnu-content {
    background-color: var(--sidebar-ul-hover-color);
    padding-left: 20px;
    padding-top: 2px;
    font-size: small;
    transition: all 0.1s ease-in-out;

}

.submemnu-content:hover {
    /* border-left: 2px solid var(--body-main-orange-color); */
    border-left: 5px solid var(--body-main-orange-color);

}

.sidebar a {
    display: block;
    padding: 10px;
    color: var(--sidebar-font-color);
    text-decoration: none;
}

.sidebar a:hover {
    background-color: var(--sidebar-ul-hover-color);
}

.submenu {
    display: none;
    padding-top: 5px;


}

.submenu.active {

    display: block;

}

/* .submenu.active a:hover
{
    background-color: var(--button-hover-bg-dark)
    
} */



.toggle-button {
    background-color: transparent;
    color: var(--white);
    /* color: #c46506; */
    border: none;
    padding: 6px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.5 ease-in-out;

}

.toggle-button:hover {
    background-color: var(--white);
    color: var(--body-main-dark-color);
    border-radius: 5px;

}

.sidebar.hidden {
    transform: translateX(-100%);
}

.head.full {
    width: 100%;
    margin-left: 0;
    transition: all 0.3s ease-in-out;
}

.head-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.main-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 30px);
    /* Adjust height according to your needs */
    overflow-y: auto;
    /* Enable vertical scrolling */
    margin-top: 10px;
}

/* ----------- Design For Dynamic Headquater ------------------*/


.bg-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 8px 0px 8px;
}

.pageheader {
    background-color: rgba(0, 0, 0, .04) !important;
    /* background-image: url(../../../public/header-Image.jpg); */
    /* background-image: linear-gradient(to bottom right,#e2f2ff,#c1e0f9); */
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

.bg-head {
    font-size: 16px;
    font-weight: 500;
    color: var(--sidebar-bg-dark);
}

.colorgraph {
    height: 1.5px;
    border-top: 0;
    background: #c4e17f;
    border-radius: 5px;
    background-image: linear-gradient(to right, #62c2e4, #62c2e4 12.5%, #669ae1 12.5%, #669ae1 25%, #72c7e6 25%, #72c7e6 37.5%, #6f9fe2 37.5%, #6f9fe2 50%, #db9dbe 50%, #db9dbe 62.5%, #c49cde 62.5%, #c49cde 75%, #669ae1 75%, #669ae1 87.5%, #c4e17f 87.5%, #c4e17f);
}

.shadow {
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    background-image: linear-gradient(to bottom right, #e2f2ff, #c1e0f9);
    /* background-image: url(../../../assets//Images/indiangradient.PNG); */
    /* background-image: url(../../../assets/Images/indiangradient.PNG);    */
    /* background-image: url(header-Image.jpg); */
}

.margin1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: small;
}

.card-container {
    width: 100%;
    /* background-color: var(--button-hover-bg-dark); */
    margin-top: 10px;
    /* box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.75);
    border-radius: 12px; */
    overflow: hidden;
}


/*---------- CSS for Grid Table -----------------------*/


.datatable1 {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 340px;
    min-height: 340px;
    width: inherit;
    max-width: 1110px;

}

.datatable1 table {
    border-collapse: collapse;
    width: 100%;
}

.datatable1 thead {
    position: sticky;
    top: 0;
    background-color: var(--body-main-light-color);
    padding: 10px;
    font-size: 12px;
    color: var(--body-main-orange-color);
}

/* 
thead tr {
    border-radius: 10px;  
}

thead tr th {
    cursor: pointer;

} */



/* thead tr th:first-child {
    border-radius: 10px 0px 0 0;
}

thead tr th:last-child {
    border-radius: 0 10px 0 0;
} */

.datatable1 th,
.datatable1 td {
    padding: 10px;
    min-width: 90px;
}

.datatable1 th {
    text-align: left;
}

.datatable1 tbody tr {
    font-size: 14px;
}

/* .datatable1 tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  } */

.pagination-container {

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    /* background-color: #c7cdd3; */
    /* border-radius: 0 0 10px 10px; */


}

.paginationbtn {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}


.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    font-size: 15px;
    padding-right: 20px;
}

.page-item {

    display: inline-block;
    margin: 0 5px;

}

.page-link {

    display: inline-block;
    padding: 5px 10px;
    /* border-radius: 5px; */
    /* border: 1px solid #ccc; */
    text-decoration: none;
    color: #333;
    cursor: pointer;

}

.active {

    /* border-bottom: 2px solid var(--body-main-dark-color); */
    display: none;
}


.selecopt {
    margin-top: 12px;
    padding: 10px 10px 20px 10px;
    justify-content: space-between;
}

.selsp {
    font-size: 12px;
}

.selpageshow {
    margin: 0 32px 0 24px;
}

/* .sel1 {
    height: 20px;
    width: 50px;
    /* border: 1px solid #8d9194; 
} */

.sel1 {
    appearance: none;
    border: none;
    -webkit-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8"><polygon points="0,0 7,8 14,0"/></svg>'), linear-gradient(#f3f3f3, #f3f3f3);
    background-position: right center, 0 0;
    background-repeat: no-repeat;
    background-size: 10px 10px, 100% 100%;
    border-bottom: 1px solid var(--body-main-dark-color);
    border-radius: 4px;
    padding: 5px;
    width: 50px;
    height: 30px;
    text-align: left;
    cursor: pointer;
}

.sel1:focus {
    outline: none;
    box-shadow: 0 0 2px 1px #007bff;

}

.sel1 option {

    position: absolute;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
}

.actionbutton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: var(--body-main-dark-color);
    font-size: 15px;

}


.mani {
    width: 100%;
    overflow-y: auto;
    display: flex;
    align-items: center;
}

.mani2 {
    width: 100%;
    display: flex;
    align-items: center;
}

.center-pop {
    display: flex;
    flex-direction: row;
}

.gridse {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form__group {
    position: relative;
    padding: 20px 0 0;
    width: 100%;
    max-width: 180px;
    margin-top: -6px;
    margin-left: 20px;
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: none;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 13px;
    color: gray;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

}

.form__field::placeholder {
    color: transparent;
}

.form__field:placeholder-shown~.form__label {
    font-size: 10px;
    cursor: text;
    top: 20px;
}

.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: #9b9b9b;
    pointer-events: none;
}

.form__field:focus {
    padding-bottom: 6px;
    font-weight: 500;
    border-width: 3px;
    /* border-image: linear-gradient(to right, #116399, #38caef); */
    border-image-slice: 1;

}

.form__field:focus~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: var(--body-main-dark-color);
    font-weight: 500;
}

/* reset input */
.form__field:required,
.form__field:invalid {
    box-shadow: none;
}

.selinput {
    font-family: inherit;
    width: 100%;
    border: none;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    margin-left: 10px;
    margin-top: 12px;
}

.btn12 {
    background-color: var(--new-blue);
    border-radius: 50%;
    border: none;
    height: 40px;
    width: 40px;
    font-size: 12px;
    color: var(--white);
    font-weight: 800;
    margin-top: 20px;
    margin-left: 10px;
    padding-top: 3px;
    cursor: pointer;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
    justify-content: center;
    align-items: center;
}

.popup {
    background-color: black;
    height: 100px;
    width: 500px;
}

.mlsel:hover {
    background-color: #333;
}


.btns {
    width: 90%;
    display: flex;
    justify-content: space-between;
    left: 18px;
    padding-right: 20px;
    position: absolute;
    bottom: 10px;

}

.btn1 {
    /* background-color: var(--body-main-dark-color); */
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--body-main-dark-color);
    color: var(--body-main-dark-color);
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    align-content: center;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);




}

.btn1 span {
    padding-right: 5px;
    align-items: center;

}

.btn1:hover {
    background-color: var(--body-main-dark-color);
    color: var(--button-color-light);
}

.btn123 {
    /* background-color: var(--body-main-dark-color); */
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    height: 4%;
    flex-direction: row;
    display: flex;
    border: 1px solid var(--body-main-dark-color);
    color: var(--body-main-dark-color);
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);

}

.inactive {
    display: none;
}


.btndisable
{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}
.activebtn {
    display: block;
}

.inputGroup {
    font-family: 'Segoe UI', sans-serif;
    margin: 1em 0 1em 0;
    max-width: 90%;
    position: relative;
}

.inputGroup .inputtxt {
    font-size: 100%;
    padding: 0.8em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 10px;
    width: 100%;
}

.inputGroup .inputlbl {
    font-size: 100%;
    position: absolute;
    left: 0;
    padding: 0.8em;
    margin-left: 0.5em;
    pointer-events: none;
    transition: all 0.3s ease;
    color: rgb(100, 100, 100);
}

.inputGroup :is(.inputtxt:focus, .inputtxt:valid)~.inputlbl {
    transform: translateY(-50%) scale(.9);
    margin: 0em;
    margin-left: 1.3em;
    padding: 0.4em;
    background-color: #fff;
    color: var(--green);
}

.inputGroup :is(.inputtxt:focus, .inputtxt:valid) {
    /* border-color: rgb(150, 150, 200); */
    border-color: var(--green);
}


/* ------------------- Chips Container --------*/

.chips-container {
    margin-right: 20px;
    padding-right: 5px;
    max-height: 70px;
    overflow-y: auto;

}

.userChips-container {
    margin-right: 20px;
    padding-left: 15px;
    max-height: 70px;
    overflow-y: auto;
    /* padding-top: 30px; */
    max-height: 70%;
    margin-top: 35px !important;

}

/* ----------- Popup Container -----------*/

.pop-main {
    width: 100%;
    overflow-x: hidden;
}

.pop-head {
    margin: 0;
    width: 100%;
    background-color: var(--body-main-light-color);
    justify-content: center;
    align-items: center;
    height: 4%;
    padding: 5px;
    font-weight: 600;
    color: var(--body-main-dark-color);
}

.treePopup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    top: 0;
    margin-top: -5px;
    z-index: 1;
}

.popup-content
{
    padding: 0px !important;
    
}
.popup-content::-webkit-scrollbar{
    height: 0px !important;
    width: 0px !important;
}
.pop-form {
    margin-top: 3%;
    align-items: center;

}



.grid-container {
    display: grid;
    padding: 5%;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns with equal width */
    grid-gap: 10px;
    /* Gap between grid items */
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
}

.chips-grid-container {
    display: grid !important; 
    /* padding: 5%; */
    grid-template-columns: repeat(8, 1fr);
    /* Two columns with equal width */
    grid-gap: 8px;
    /* Gap between grid items */
}

.grid-item {
    /* Additional styling for grid items */
}

.popup_filter1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 100000;

}

.popup_filter2 {
    display: flex;
    flex-direction: row;
    align-items: center;   
}

/*   ---------- CSS for Add Area ------- */

.area_main {
    /* padding: 10px; */
}

.area_content {
    width: inherit;
    /* border: 1px solid var(--body-main-dark-color); */
    border-radius: 10px;
    padding: 5px;

}



.grid-container-area {
    display: grid;
    width: inherit;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px;
}


.Area_btn {
    display: flex;
    flex-direction: ro;
}


/* ---------------------------- cancel Popup CSS  ------------------------ */

.confirmation-modal
{
    /* padding: 10px; */
}

.confirmation-modal p
{
    color: var(--body-main-dark-color);
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 0.9rem;
}

.canel_btns
{
    display: flex;
    flex-direction: row;
    margin-top: -10px;
    /* padding: 10px; */
    justify-content: center;
    /* padding-left: 10%;
    padding-right: 10%; */
}

.confirmation-modal .canel_btns .btn123
{
    background-color: var(--body-main-dark-color);
    color: var(--button-color-light);
   
}

.booth-container .MuiDialogContent-root
{
    padding-top: 32px !important;
}

.booth_store {
    width: 90%;
    border: 1px solid black;
    height: 200px;
    position: relative;
    margin-top: 30px;
    color: var(--body-main-dark-color);
    border: 1px solid #A6A6A6;
    font-size:14px;
}

.select_booth_option {
    position: absolute;
    transform: translate(10px, -25px);
    background-color: #fff;
    border: 1px solid #A6A6A6;
    padding: 10px;
    border-radius: 360px;
    display: flex;
    align-items: center;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
}

.booth_radio_buttons {
    display: flex;
    align-items: center;
}

.li-btn
{
    background: none;
    border: none;
    color: var(--body-main-dark-color);
}

/* ---------------------- MUI Table CSS -----------------*/
.MuiInput-underline:after {
    border-bottom: 2px solid var(--body-main-dark-color) !important;
}

/* .card-container .MuiPaper-root {
    height: 65vh !important;

} */

/* .css-1mxz8qt-MuiPaper-root
{
    height: 65vh !important;
} */


/* .MuiTableContainer-root {
    max-height: 85% !important;
} */

.popup_filter2 .MuiFormControl-root {
    width: 200px !important;
    margin: 1% !important;  
}

.popup_filter2 .MuiSelect-select
{
    font-size: 12px !important;
}

.popup_filter2 .MuiInputBase-input
{
    font-size: 12px !important;
}

.MuiSvgIcon-root
{
    font-size: 1.2rem !important;
}

.MuiIconButton-root
{
    font-size: 1rem !important; 
}

.MuiSelect-select
{
    font-size: 12px !important;
}
/* .css-i4bv87-MuiSvgIcon-root {
    font-size: 1.2rem !important;
} */

/* .css-1a5r00q-MuiButtonBase-root-MuiIconButton-root {
    font-size: 1rem !important;
} */

/* .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    font-size: 12px !important;
} */

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 0.8rem !important;
}

.MuiFormControl-root
{
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

/* .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    font-size: 14px !important;
} */

.MuiInputBase-root
{
    font-size: 14px !important;
}
.MuiAutocomplete-option {
    font-size: 12px !important;
}


.card-container .MuiPaper-root
{
    box-shadow: none !important;
}
.css-2sy9q2-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--body-main-dark-color) !important;
}

@media (max-width:850px) {
    .grid-container-area {
        grid-template-columns: repeat(4, 1fr);
    }
    .mani2 {
        overflow-x: auto;
    }

}

@media (max-width:750px) {
    .sidebar {
        margin-top: 50px;
        z-index: 1000;
        width: 100%;

    }

    .head {
        width: 100%;
        margin-left: 0;
        transition: all 0.3s ease-in-out;
    }

    .mani2 {
        overflow-y: auto;
    }

    .grid-container-area {
        grid-template-columns: repeat(3, 1fr);
    }

    .popup_filter1 {
        display: flex;
        flex-direction: column;
    }

    .popup_filter2 {
        display: flex;
        flex-direction: column;
        width: 100%;

    }

    .chips-container {
        width: 50%;
        margin-top: 10px;
    }

    .popup_filter2 .MuiInputBase-root {
        width: 100% !important;
    }


    .popup_filter2 .css-1nrlq1o-MuiFormControl-root {
        width: 100% !important;
    }
    .popup_filter2 .MuiFormControl-root
    {
        width: 90% !important;
    }
    .popup_filter2 .MuiTextField-root
    {
        width: 34rem !important;
    }
   
   
}

@media (max-width:650px) {
    .grid-container-area {
        grid-template-columns: repeat(2, 1fr);
    }

   
}

@media (max-width:450px) {
    .grid-container-area {
        grid-template-columns: repeat(1, 1fr);
    }

    .popup_filter1 {
        display: flex;
        flex-direction: column;
    }

    .popup_filter2 .MuiTextField-root
    {
        width: 17rem !important;
    }


}
:root {
    --body-main-orange-color: #f68109;
    --body-main-green-color: #008000;
    --body-main-blue-color: #243C5C;
    /* --button-green-light: #2f8f2f; */
    --button-green-light: #243C5C;
    --body-main-light-color: #B3C9E6;
    /* --body-main-light-color: #6182aac7; */
    /* --body-main-light-color: #849dbd; */
    /* --sidebar-bg-light-color: #f1f1f1; */
    --sidebar-bg-light-color: #e2e5e7;
    --sidebar-ul-hover-color: #ddd;
    --white: #fff;
    --sidebar-font-color: #333;
    --sidebar-bg-light: rgb(37, 37, 66);
    --sidebar-bg-dark: #555;
    --main-bg-light: #fff;
    --main-bg-dark: #444;
    --button-bg-light: #007bff;
    --button-hover-bg-light: #0056b3;
    --button-bg-dark: #bbb;
    --button-hover-bg-dark: #999;
    --button-color-light: #fff;
    --button-color-dark: #333;
    --table-border-light: #ddd;
    --table-border-dark: red;
    --text-color-dark: #fff;
}

.login_main {
    height: 100vh;
    width: 100%;
    /* background: #ff9933;
    background: linear-gradient(159deg, #ff9933 -30%, #ffffff 50%, #138808 130%);
    background: -webkit-linear-gradient(159deg, #ff9933 -30%, #ffffff 50%, #138808 130%);
    background: -moz-linear-gradient(159deg, #ff9933 -30%, #ffffff 50%, #138808 130%); */



    background-image: url(../../assets/Images/indiangradient.PNG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_card {
    /* height: 38%; */
    width: 40%;
    background-color: #0056b3;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    overflow: hidden;
}

.login_1 {
    width: 40%;
    /* background: #f68109;
    background: linear-gradient(50deg, #f68109 0%, #ffffff 50%, #008000 100%);
    background: -webkit-linear-gradient(50deg, #f68109 0%, #ffffff 50%, #008000 100%);
    background: -moz-linear-gradient(50deg, #f68109 0%, #ffffff 50%, #008000 100%); */

    background: #ff9933;
    background: linear-gradient(142deg, #ff9933 0%, #ffffff 50%, #138808 100%);
    background: -webkit-linear-gradient(142deg, #ff9933 0%, #ffffff 50%, #138808 100%);
    background: -moz-linear-gradient(142deg, #ff9933 0%, #ffffff 50%, #138808 100%);
    /* border-right: 2px solid var(--body-main-blue-color); */

    display: flex;
    justify-content: center;
    align-items: center;

}

.login_img {
    height: 50%;
    width: 50%;
}

.login_2 {
    width: 60%;
    background-color: white;
    border-left: 2px solid var(--body-main-blue-color);

}

.login_header {
    width: 100%;
    height: 18%;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.329);
    -webkit-box-shadow: 0px 3px 7px 0px rgba(124, 124, 124, 0.466);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_header h3 {
    color: var(--body-main-dark-orange);
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;

}

.login_content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 15px;
}

.login_btn {
    width: 90%;
    background-color: var(--button-green-light);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.329);
    -webkit-box-shadow: 0px 3px 7px 0px rgba(124, 124, 124, 0.466);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
    border: 1px solid var(--body-main-dark-color);
    padding: 10px;
    border-radius: 5px;
    margin-top: 8px;
    cursor: pointer;
    color: var(--button-color-light);
}

.login_btn span {
    display: flex;
    justify-content: center;
    align-items: center;

}

.login_btn:hover {
    background-color: var(--body-main-orange-color);
    border: 1px solid var(--body-main-orange-color);
    color: var(--button-color-light);
}

.login_btn span:hover {
    background-color: var(--body-main-orange-color);
    color: var(--button-color-light);
}

/* .MuiFormLabel-root-MuiInputLabel-root.Mui-focused */
.login_content .MuiFormLabel-root.Mui-focused {
    color: var(--body-main-dark-orange) !important;
}

.login_content .MuiInput-underline:after {
    border-bottom: 2px solid var(--body-main-green-color) !important;
}

.login_content .MuiSvgIcon-root {
    color: var(--body-main-dark-orange) !important;
}

.login_btn .MuiSvgIcon-root {
    color: var(--button-color-light) !important;
}

.MuiLinearProgress-bar {
    /* background-color: var(--body-main-green-color) !important; */
    background: #ff9933 !important;
    background: linear-gradient(104deg, #ff9933 0%, #138808 100%) !important;
    background: -webkit-linear-gradient(104deg, #ff9933 0%, #138808 100%) !important;
    background: -moz-linear-gradient(104deg, #ff9933 0%, #138808 100%) !important;
}

@media (max-width:750px) {
    .login_card {
        width: 80%;
    }

    .login_1 {
        display: none;
    }

    .login_2 {
        width: 100%;

    }


}